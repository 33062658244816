import Button from 'components/button/button'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { BiBorderRadius, BiFontSize } from 'react-icons/bi'
import { styled } from 'stitches/stitches.config'

export const StyleContent = styled(Box, {
  background: '$white',
  borderRadius: '$3',
  border: '1px solid $border',
  boxShadow: '$subtle',
})

export const StyleFlex = styled(Flex, {
  borderLeft: '2px solid $border',
  pl: '$2',
})

export const StyledProductItem = styled(Flex, {
  p: '$2',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$2',
})

export const StyledProductDetails = styled(Flex, {
  //   p: '$4',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$3',
})

export const StyledButton = styled(Button, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mx: '$5',
  mb: '$9',
})

export const StyledDetails = styled(Flex, {
  p: '$3 $5',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$3',
})

export const StyledSupplierSearch = styled('div', {
  '.tab': {
    // mt: '$5',
  },
  '.supplier-card': {
    margin: '$3',
    p: '$3',
    border: '1px solid #ccc',
    // background: 'white',
    '&__logo': {
      background: 'white',
      border: '1px solid #eee',
      borderRadius: '$round',
      overflow: 'hidden',
      width: '5rem',
      height: '5rem',
      img: {
        objectFit: 'cover',
        width: '100%',
      },
    },
    '.btn': {
      fontSize: '1.4rem',
      border: '1px solid $secondary',
      p: '1.5rem 3rem',
    },
    '.gallery': {
      mt: '$5',

      '.product-img': {
        img: {
          borderRadius: '$4',
          mb: '$2',
          width: '15rem',
          height: '15rem',
          objectFit: 'cover',
        },
      },
    },
  },
})

export const StyledFilter = styled(Box, {
  my: '2rem',
  position: 'relative',
  '.label': {
    fontSize: '1.2rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    backgroundColor: '#F4F2EF',
    borderRadius: '4rem',
    p: ' 1rem',
    width: 'max-content',
  },
  '.hasValue': {
    backgroundColor: '#DBDAC9',
    p: '.5rem .75rem',
  },
  '.value': {
    backgroundColor: '#F4F2EF',
    borderRadius: '4rem',
    p: '.5rem 1.5rem',
  },
  '.select': {
    minWidth: 'max-content',
    mt: '1rem',
    position: 'absolute',
  },
})
export const StyledHeader = styled('h1', {
  fontSize: '2rem',
  my: '2rem',
})
