import { ReactComponent as Icon } from 'assets/svg/financial-icon.svg'
import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import { Text } from 'components/text/text'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import React from 'react'
import { HiPlus } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { AddAccountDrawer } from '../components/add-account-drawer'
import FinancialAccount from '../components/financial-accounts'
import { FinancialProps } from '../financial'

export const FinancialAccounts: React.FC<FinancialProps> = (props) => {
  const { fetching, financialAccounts, refetchFinancialAccounts } = props

  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')
  const [showDrawer, setShowDrawer] = React.useState<boolean>(shouldOpenDrawer)

  return (
    <>
      <AddAccountDrawer
        visible={showDrawer}
        refetchFinancialAccounts={() => refetchFinancialAccounts?.()}
        onClose={() => setShowDrawer(false)}
      />
      <PageHeader
        title="Financial Accounts"
        extra={
          <Flex align="center" gutter="4">
            <Button prepend={<HiPlus />} onClick={() => setShowDrawer(true)}>
              Add New Account
            </Button>
          </Flex>
        }
      />
      <StyledLayoutContent>
        {fetching && (
          <Flex
            direction="column"
            align="center"
            justify="center"
            gutter="2"
            css={{ background: '$white', borderRadius: '$3', p: '$8 $4' }}
          >
            <Loader size="md" />
          </Flex>
        )}
        {!fetching && !financialAccounts.length && (
          <Flex
            direction="column"
            align="center"
            justify="center"
            gutter="2"
            css={{ background: '$white', borderRadius: '$3', p: '$8 $4' }}
          >
            <Icon />
            <Text weight="bold">Add or Import Financial Accounts</Text>
            <Text
              css={{ width: '30%', textAlign: 'center', color: '$secondary' }}
              size="xs"
            >
              Port over all your existing financial accounts and let Atlas
              sort and reconcile them for you.
            </Text>

            <Flex gutter="3" css={{ mt: '$6' }}>
              <Button
                onClick={setShowDrawer.bind(null, true)}
                prepend={<HiPlus />}
              >
                Add New Account
              </Button>
            </Flex>
          </Flex>
        )}
        {!!financialAccounts.length && (
          <Flex direction="column" gutter="5">
            {financialAccounts.map((account) => (
              <FinancialAccount
                financialAccounts={financialAccounts}
                selectedFinancialAccount={account}
                key={account.accountNumber}
                refetchFinancialAccounts={() => refetchFinancialAccounts()}
              />
            ))}
          </Flex>
        )}
      </StyledLayoutContent>
    </>
  )
}
