/* eslint-disable @typescript-eslint/no-explicit-any */
import { Accounting } from 'pages/accounting/accounting'
import { Contacts } from 'pages/contacts/contacts'
import { Financial } from 'pages/financial/financial'
import { Inventory } from 'pages/inventory/inventory'
import OverviewPage from 'pages/overview/overview'
import { Purchases } from 'pages/purchases/purchases'
import { Reports } from 'pages/reports/reports'
import { Sales } from 'pages/sales/sales'
import Settings from 'pages/settings/settings'
import { Taxes } from 'pages/taxes/taxes'
import { DashboardLayoutProps } from '../components/layouts/layout.types'
import SettingsLayout from '../components/layouts/settings-layout'

type DashboardRoute<T = any> = {
  path?: string
  layout?: React.FC<T>
  layoutProps?: DashboardLayoutProps
  element: React.FC<T>
  provider?: any
  isIndex?: boolean
}

const productDashboardRoutes: Array<DashboardRoute> = [
  {
    path: '/',
    isIndex: true,
    element: OverviewPage,
  },
  {
    path: '/financial/*',
    element: Financial,
  },
  {
    path: '/contacts/*',
    element: Contacts,
  },
  {
    path: '/accounting/*',
    element: Accounting,
  },
  {
    path: '/reports/*',
    element: Reports,
  },
  {
    path: '/settings/*',
    element: Settings,
    layout: SettingsLayout,
  },
  {
    path: '/sales/*',
    element: Sales,
  },
  {
    path: '/purchases/*',
    element: Purchases,
  },
  {
    path: '/inventory/*',
    element: Inventory,
  },
  {
    path: '/taxes/*',
    element: Taxes,
  },
]

export default productDashboardRoutes
