import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { ContactsTable } from './components/contacts-table'
import { StyleContent } from './contacts.styles'
import TypeAheadSelect from 'components/type-ahead-select'
import { Box } from 'components/layout'
import { FiSearch } from 'react-icons/fi'
import { Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import SupplierSearch from './supplier-search'

export const Contacts = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <PageHeader
              title="Suppliers"
              extra={<SupplierSearchInput width="40rem" />}
            />
            <StyledLayoutContent>
              <StyleContent>
                <Tabs
                  activeKey="1"
                  tabs={[
                    {
                      key: '1',
                      title: 'All Suppliers',
                      content: <ContactsTable />,
                    },
                  ]}
                />
              </StyleContent>
            </StyledLayoutContent>
          </>
        }
      />
      <Route path="/search" element={<SupplierSearch />} />
    </Routes>
  )
}

export function SupplierSearchInput({
  width,
  searchQuery,
}: {
  width?: string
  searchQuery?: string
}) {
  const navigate = useNavigate()

  return (
    <Box
      css={{
        width,
      }}
    >
      <TypeAheadSelect
        required
        isSearchable
        placeholder="Find manufacturers here"
        options={[
          {
            label: 'electric bike',
            value: 'electric bike',
          },
          {
            label: 'samsung mobile phone',
            value: 'samsung mobile phone',
          },
          {
            label: 'iphone 15 pro max',
            value: 'iphone 15 pro max',
          },
          {
            label: 'human hair wigs',
            value: 'human hair wigs',
          },
        ]}
        onChange={(value) => {
          navigate(`/dashboard/contacts/search?q=${value}`)
        }}
        defaultInputValue={searchQuery}
        customDropdownIndicator={() => <FiSearch />}
      />
    </Box>
  )
}
