import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  StyledFilter,
  StyledSupplierSearch,
  StyledHeader,
} from './contacts.styles'
import { SupplierSearchInput } from './contacts'
import Flex from 'components/layout/flex'
import TypeAheadSelect from 'components/type-ahead-select'
import Text from 'components/text'
import { FaRegStar } from 'react-icons/fa'
import { GrCatalogOption, GrLocation } from 'react-icons/gr'
import { TbWorld } from 'react-icons/tb'
import { LiaCertificateSolid } from 'react-icons/lia'
import { Tabs } from 'components/tabs/tabs'
import Stack from 'components/layout/stack'
import { VscVerifiedFilled } from 'react-icons/vsc'
import Button from 'components/button/button'
import { HiOutlineBriefcase, HiUserAdd } from 'react-icons/hi'
import Drawer from 'components/drawer/drawer'
import Textarea from 'components/textarea'

export default function SupplierSearch() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const searchQuery = queryParams.get('q')

  return (
    <StyledSupplierSearch>
      <StyledHeader>Your search for {searchQuery}</StyledHeader>
      <SupplierSearchInput searchQuery={searchQuery as string} />

      <Flex align="start" gutter="4">
        <Filter
          icon={<GrCatalogOption />}
          name="Capabilities"
          options={[
            {
              label: 'R&D capabilities',
              value: 'R&D capabilities',
            },
            {
              label: 'Cross-category consolidation',
              value: 'Cross-category consolidation',
            },
            {
              label: 'Supplies Fortune 500 companies',
              value: 'Supplies Fortune 500 companies',
            },
            {
              label: 'Quality management certified',
              value: 'Quality management certified',
            },
            {
              label: 'OEM for well-known brands',
              value: 'OEM for well-known brands',
            },
            {
              label: 'Supplier assessment procedures',
              value: 'Supplier assessment procedures',
            },
            {
              label: 'Minor customization',
              value: 'Minor customization',
            },
            {
              label: 'Agile supply chain',
              value: 'Agile supply chain',
            },
            {
              label: 'Warranty available',
              value: 'Warranty available',
            },
            {
              label: '3D design capabilities',
              value: '3D design capabilities',
            },
          ]}
        />
        <Filter
          icon={<FaRegStar />}
          name="Store reviews"
          options={[
            {
              label: '4.0 & up',
              value: '4.0 & up',
            },
            {
              label: '4.5 & up',
              value: '4.5 & up',
            },
            {
              label: '5.0',
              value: '5.0',
            },
          ]}
        />
        <Filter
          icon={<TbWorld />}
          name="Supplier country"
          options={[
            {
              label: 'China',
              value: 'China',
            },
            {
              label: 'United state',
              value: 'United state',
            },
            {
              label: 'Nigeria',
              value: 'Nigeria',
            },
          ]}
        />
        <Filter
          icon={<LiaCertificateSolid />}
          name="Management certifications"
          options={[
            {
              label: 'ISO',
              value: 'ISO',
            },
            {
              label: 'BSCI',
              value: 'BSCI',
            },
            {
              label: 'AEO',
              value: 'AEO',
            },
            {
              label: 'IATF 16949',
              value: 'IATF 16949',
            },
            {
              label: 'AS9100D',
              value: 'AS9100D',
            },
          ]}
        />
      </Flex>

      <Flex align="center" gutter="4" className="tab">
        <Tabs
          defaultValue="1"
          tabs={[
            {
              key: '1',
              title: 'Suppliers',
              content: (
                <Stack css={{ mt: '2rem' }}>
                  <SupplierCard />
                  <SupplierCard />
                </Stack>
              ),
            },
            {
              key: '2',
              title: 'Product',
              content: (
                <Stack css={{ mt: '2rem' }}>
                  <SupplierCard />
                  <SupplierCard />
                </Stack>
              ),
            },
          ]}
        />
      </Flex>
    </StyledSupplierSearch>
  )
}

function Filter({
  name,
  options,
  icon,
}: {
  name: string
  icon: any
  options: { label: string; value: string }[]
}) {
  const [showSelect, setShowSelect] = React.useState(false)
  const [value, setValue] = React.useState<string | null>(null)

  // Create a ref to the dropdown container
  const ref = React.useRef<HTMLDivElement>(null)

  // useEffect to handle clicks outside the component
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowSelect(false)
      }
    }

    // Add event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <StyledFilter ref={ref}>
      <Flex
        onClick={() => setShowSelect(!showSelect)}
        className={`label ${value ? 'hasValue' : ''}`}
      >
        {icon}
        {name}
        {value && <div className="value">{value}</div>}
      </Flex>
      {showSelect && (
        <TypeAheadSelect
          className="select"
          required
          isSearchable
          placeholder="Find manufacturers here"
          options={options}
          onChange={(value) => {
            setValue(value as string)
            setShowSelect(false)
          }}
        />
      )}
    </StyledFilter>
  )
}

function SupplierCard() {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Flex direction="column" className="supplier-card">
      <Flex align="center" gutterX="4">
        <Flex align="center" justify="center" className="supplier-card__logo">
          <img
            src="https://secure.rezserver.com/public/media/img/air_logos2/DL.png"
            alt=""
          />
        </Flex>
        <Flex direction="column" gutter="2">
          <Flex align="center" gutter="1">
            <Text color="$black">
              Jinhua Queene Electric Technology Co., Ltd.{' '}
            </Text>
            <VscVerifiedFilled color="#59A160" />
          </Flex>
          <Flex align="center" gutter="2">
            <Flex align="center" gutter="1">
              <FaRegStar color="#6F7061" />
              <Text color="#6F7061" size="xs">
                4.8/5
              </Text>
            </Flex>
            •
            <Flex align="center" gutter="1">
              <GrLocation />
              <Text color="#6F7061" size="xs">
                China
              </Text>
            </Flex>
            •
            <Flex align="center" gutter="1">
              <HiOutlineBriefcase />
              <Text color="#6F7061" size="xs">
                5 years
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex css={{ ml: 'auto' }} gutter="3">
          <div onClick={() => setIsOpen(true)} role="button" className="btn">
            Contact supplier
          </div>
          <div role="button" className="btn">
            Call now
          </div>
        </Flex>
      </Flex>
      <Flex className="gallery" align="center" gutter="2">
        <Stack className="product-img">
          <img
            src="https://s.alicdn.com/@sc04/kf/Hbdba59b3b347447caf9e4fb11caef1103.jpg"
            alt=""
          />
          <Text size={'xxs'}>US$2,500.00</Text>
        </Stack>
        <Stack className="product-img">
          <img
            src="https://s.alicdn.com/@sc04/kf/H6124638c748d438982e107a4278ded5d8.jpg"
            alt=""
          />
          <Text size={'xxs'}>US$2,500.00</Text>
        </Stack>
        <Stack className="product-img">
          <img
            src="https://s.alicdn.com/@sc04/kf/Hbdba59b3b347447caf9e4fb11caef1103.jpg"
            alt=""
          />
          <Text size={'xxs'}>US$2,500.00</Text>
        </Stack>
      </Flex>
      <SendInquiry isOpen={isOpen} setIsOpen={setIsOpen} />
    </Flex>
  )
}

function SendInquiry({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: any
}) {
  return (
    <Drawer
      title="Send inquiry to Jinhua Queene Electric"
      titleIcon={<HiUserAdd size="2rem" color="#ABB3B9" />}
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      fullHeight={false}
      placement="right"
      footer={
        <Flex gutterX="2">
          <Button
            size="md"
            appearance="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            size="md"
            // isLoading={fetching || creatingSupplier}
            // disabled={!formIsComplete}
            onClick={() => {}}
          >
            Send enquiry
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="3" css={{ p: '$5' }}>
        <Textarea
          // {...(register('description') as any)}
          required
          // disabled={isSubmitting}
          label="Message to the Supplier"
          placeholder="Enter product details such as color, size, materials and other specific requirments."
          // value={watch('description')}
          // error={errors.description?.message}
        />
      </Flex>
    </Drawer>
  )
}
