import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import { ReactComponent as LinkSentBg } from 'assets/svg/link-sent.svg'
import Button from 'components/button/button'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useResetPasswordMutation } from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React from 'react'
import { HiChevronLeft } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import { extractGraphqlErrors } from 'utils/helpers'
import {
  StyledCol,
  StyledContent,
  StyledLink,
  StyledLogoContainer,
  StyledRow,
} from '../auth.styles'
import { Footer } from '../components/footer/footer'

interface ResetPasswordProps {}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const navigate = useNavigate()
  const [passwordReset, setPasswordReset] = React.useState(false)
  const toast = useToast()
  const { token } = useParams()
  const { register, values, errors, hasErrors, formIsComplete } = useForm({
    fields: {
      password: '',
    },
  })

  const [{ fetching }, ResetPassword] = useResetPasswordMutation()

  function notifyError(error?: string) {
    toast({
      content: error ?? 'something went wrong',
      status: 'error',
    })
  }

  async function handleResetPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      const response = await ResetPassword({
        input: {
          password: values.password,
          token: String(token),
        },
      })
      const error = extractGraphqlErrors(response, 'forgotPassword')

      if (error) {
        notifyError(error)
        return
      }
      setPasswordReset(true)
    } catch (error) {
      notifyError('Something went wrong. Please try again')
    }
  }

  return (
    <StyledRow gutter={1}>
      <StyledCol span={4}>
        <StyledContent>
          <StyledLogoContainer align="center" justify="between">
            <AccounteerLogo style={{ width: '7rem' }} />
            <StyledLink to="/">
              <HiChevronLeft color="#ABB3B9" size="1.5rem" />{' '}
              <Text size="xxs">Return to Login</Text>
            </StyledLink>
          </StyledLogoContainer>
          <div>
            {!passwordReset && (
              <>
                <Flex gutterY={2} direction={'column'}>
                  <Text variant="h1" size="lg">
                    Set new password
                  </Text>
                  <Text variant="body2">
                    Enter a new password for your Atlas account.
                  </Text>
                </Flex>
                <form onSubmit={handleResetPassword}>
                  <Flex gutterY={4} direction={'column'}>
                    <Input
                      label="New Password"
                      placeholder="Password"
                      type="password"
                      required
                      {...register('password')}
                      error={errors.password}
                    />
                    <Button
                      isLoading={fetching}
                      disabled={fetching || hasErrors || !formIsComplete}
                    >
                      Set New Password
                    </Button>
                  </Flex>
                </form>
              </>
            )}
            {passwordReset && (
              <>
                <Flex gutterY={6} direction={'column'}>
                  <LinkSentBg />
                  <Flex gutterY={2} direction={'column'}>
                    <Text variant="h1" size="lg">
                      New Password Set!
                    </Text>
                    <Text variant="body2">
                      Your new password has been set successfully. Proceed to
                      login with your new password.
                    </Text>
                  </Flex>

                  <Button onClick={() => navigate('/')}>Back to Login</Button>
                </Flex>
              </>
            )}

            <Footer
              justify="center"
              align="center"
              direction="column"
              gutterY={1}
            />
          </div>
        </StyledContent>
      </StyledCol>
      {/* <AuthBackground /> */}
    </StyledRow>
  )
}

export default ResetPassword
