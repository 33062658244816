import Flex from 'components/layout/flex'
import MenuBar from 'components/menu-bar/menu-bar'
import { Text } from 'components/text/text'
import React from 'react'
import {
  HiChevronLeft,
  HiOfficeBuilding,
  HiSupport,
  HiUserCircle,
} from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { DashboardLayoutProps } from './layout.types'
import { StyledCol, StyledLayoutContent, StyledRow } from './layouts.styles'

const items = [
  {
    key: 'account',
    icon: <HiUserCircle />,
    label: 'Account',
    path: '/dashboard/settings/account/profile',
    active: true,
    subMenu: [
      {
        key: '1',
        label: 'Profile',
        path: '/dashboard/settings/account/profile',
      },
      // {
      //   key: '2',
      //   label: 'Preferences',
      //   path: '/dashboard/settings/account/preferences',
      // },
      // {
      //   key: '3',
      //   label: 'Notifications',
      //   path: '/dashboard/settings/account/notifications',
      // },
      // {
      //   key: '4',
      //   label: 'Connected Accounts',
      //   path: '/dashboard/settings/account/connected-accounts',
      // },
    ],
  },

  {
    key: 'organisation',
    icon: <HiOfficeBuilding />,
    label: 'Organisation',
    path: '/dashboard/settings/organisation/general',
    active: true,
    subMenu: [
      {
        key: '1',
        label: 'General',
        path: '/dashboard/settings/organisation/general',
      },
      // {
      //   key: '2',
      //   label: 'Subscriptions',
      //   path: '/dashboard/settings/organisation/subscriptions',
      // },
      // {
      //   key: '3',
      //   label: 'Plans',
      //   path: '/dashboard/settings/organisation/plans',
      // },
      {
        key: '4',
        label: 'Members',
        path: '/dashboard/settings/organisation/members',
      },
      // {
      //   key: '5',
      //   label: 'Import/Export',
      //   path: '/dashboard/settings/organisation/import-export',
      // },
      // {
      //   key: '6',
      //   label: 'Integrations',
      //   path: '/dashboard/settings/organisation/integrations',
      // },
      {
        key: '7',
        label: 'Logs',
        path: '/dashboard/settings/organisation/logs',
      },
    ],
  },
]

const sidebarWidth = 280

const SettingsLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const navigate = useNavigate()
  return (
    <StyledRow>
      <StyledCol css={{ width: sidebarWidth, background: '#363C26' }}>
        <Flex
          direction="column"
          justify="start"
          gutter="6"
          css={{ p: '$3', height: '100%' }}
        >
          <Flex
            tab-index="0"
            role="link"
            align="center"
            justify="start"
            gutter="2"
            onClick={() => navigate('/dashboard')}
            css={{ cursor: 'pointer' }}
          >
            <HiChevronLeft size={'2rem'} color="#ABB3B9" />
            <Text size="sm" weight="semi" color="$white">
              Settings
            </Text>
          </Flex>
          <MenuBar type="settings" data={items} />
          <Flex
            css={{ mt: 'auto', cursor: 'pointer' }}
            align="center"
            justify="start"
            gutter="2"
            onClick={() => {
              // @ts-expect-error
              window.fcWidget.open()
            }}
          >
            <HiSupport size={'1.5rem'} color="#ABB3B9" />
            <Text size="xs" color="$primary">
              Help & Support
            </Text>
          </Flex>
        </Flex>
      </StyledCol>
      <StyledCol
        css={{
          width: `calc(100vw - ${sidebarWidth}px)`,
          position: 'relative',
          padding: '2rem 20rem',
        }}
      >
        <StyledLayoutContent>{children}</StyledLayoutContent>
      </StyledCol>
      {/* <QuickActions /> */}
    </StyledRow>
  )
}

export default SettingsLayout
